export const registrations = [
    {
        name: 'Breadcrumb',
        import: () => import(/* webpackChunkName: "Breadcrumb" */ '@stories/Components/Misc/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentDividerBanner',
        import: () => import(/* webpackChunkName: "ContentDividerBanner" */ '@stories/Widgets/Dividers/ContentDividerBanner/ContentDividerBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'FAQs',
        import: () => import(/* webpackChunkName: "FAQs" */ '@stories/Widgets/Content/FAQs/FAQs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturesList',
        import: () => import(/* webpackChunkName: "FeaturesList" */ '@stories/Widgets/Content/FeaturesList/FeaturesList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Form',
        import: () => import(/* webpackChunkName: "Form" */ '@stories/Widgets/Form/Form'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeaderReduced',
        import: () => import(/* webpackChunkName: "HeaderReduced" */ '@stories/Widgets/Global/HeaderReduced/HeaderReduced'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'OfficeLocationListing',
        import: () => import(/* webpackChunkName: "OfficeLocationListing" */ '@stories/Widgets/Aggregation/OfficeLocationListing/OfficeLocationListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OurOffices',
        import: () => import(/* webpackChunkName: "OurOffices" */ '@stories/Widgets/Aggregation/OurOffices/OurOffices'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PackagesList',
        import: () => import(/* webpackChunkName: "PackagesList" */ '@stories/Widgets/Content/PackagesList/PackagesList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PpcHeroBanner',
        import: () => import(/* webpackChunkName: "PpcHeroBanner" */ '@stories/Widgets/Banners/PpcHeroBanner/PpcHeroBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'HeroBanner',
        import: () => import(/* webpackChunkName: "HeroBanner" */ '@stories/Widgets/Banners/HeroBanner/HeroBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'Image',
        import: () => import(/* webpackChunkName: "Image" */ '@stories/Widgets/Content/Image/Image'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'InnerHeroBanner',
        import: () => import(/* webpackChunkName: "InnerHeroBanner" */ '@stories/Widgets/Banners/InnerHeroBanner/InnerHeroBanner'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'MediaArticleBanner',
        import: () => import(/* webpackChunkName: "MediaArticleBanner" */ '@stories/Widgets/Banners/MediaArticleBanner/MediaArticleBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'MediaArticleIntro',
        import: () => import(/* webpackChunkName: "MediaArticleIntro" */ '@stories/Widgets/Content/MediaArticleIntro/MediaArticleIntro'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'MediaCentreListing',
        import: () => import(/* webpackChunkName: "MediaCentreListing" */ '@stories/Widgets/Aggregation/MediaCentreListing/MediaCentreListing'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ExitIntentPopup',
        import: () => import(/* webpackChunkName: "ExitIntentPopup" */ '@stories/Widgets/Popups/ExitIntentPopup/ExitIntentPopup'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'OurPeopleListing',
        import: () => import(/* webpackChunkName: "OurPeopleListing" */ '@stories/Widgets/Aggregation/OurPeopleListing/OurPeopleListing'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'PeopleArticleHeader',
        import: () => import(/* webpackChunkName: "PeopleArticleHeader" */ '@stories/Widgets/Content/PeopleArticleHeader/PeopleArticleHeader'),
        config: {
            hydrate: 'lazy',
        },
    },
    {
        name: 'Reviews',
        import: () => import(/* webpackChunkName: "Reviews" */ '@stories/Widgets/Misc/Reviews/Reviews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/Content/RichText/RichText'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SearchListing',
        import: () => import(/* webpackChunkName: "SearchListing" */ '@stories/Widgets/Aggregation/Searchlisting/Searchlisting'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ServicesListing',
        import: () => import(/* webpackChunkName: "ServicesListing" */ '@stories/Widgets/Aggregation/ServicesListing/ServicesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ServicesAndClaimsListing',
        import: () => import(/* webpackChunkName: "ServiceAndClaimsCard" */ '@stories/Widgets/Aggregation/ServicesAndClaimsListing/ServicesAndClaimsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import(/* webpackChunkName: "SplitContent" */ '@stories/Widgets/Content/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentContactForm',
        import: () => import(/* webpackChunkName: "SplitContentContactForm" */ '@stories/Widgets/Content/SplitContentContactForm/SplitContentContactForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentMap',
        import: () => import(/* webpackChunkName: "SplitContentOffice" */ '@stories/Widgets/Content/SplitContentMap/SplitContentMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentOffice',
        import: () => import(/* webpackChunkName: "SplitContentOffice" */ '@stories/Widgets/Content/SplitContentOffice/SplitContentOffice'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentTestimonial',
        import: () => import(/* webpackChunkName: "SplitContentTestimonial" */ '@stories/Widgets/Content/SplitContentTestimonial/SplitContentTestimonial'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentTransport',
        import: () => import(/* webpackChunkName: "SplitContentTransport" */ '@stories/Widgets/Content/SplitContentTransport/SplitContentTransport'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SraLogo',
        import: () => import(/* webpackChunkName: "SraLogo" */ '@stories/Widgets/Misc/SraLogo/SraLogo'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticsList',
        import: () => import(/* webpackChunkName: "StatisticsList" */ '@stories/Widgets/Content/StatisticsList/StatisticsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedContent',
        import: () => import(/* webpackChunkName: "TabbedContent" */ '@stories/Widgets/Content/TabbedContent/TabbedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TeamMembers',
        import: () => import(/* webpackChunkName: "TeamMembers" */ '@stories/Widgets/Aggregation/TeamMembers/TeamMembers'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonials',
        import: () => import(/* webpackChunkName: "Testimonials" */ '@stories/Widgets/Content/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import(/* webpackChunkName: "Timeline" */ '@stories/Widgets/Misc/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TrustpilotDividerBanner',
        import: () => import(/* webpackChunkName: "TrustpilotDividerBanner" */ '@stories/Widgets/Dividers/TrustpilotDividerBanner/TrustpilotDividerBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestArticles',
        import: () => import(/* webpackChunkName: "LatestArticles" */ '@stories/Widgets/Aggregation/LatestArticles/LatestArticles'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ConveyancingCalculator',
        import: () => import(/* webpackChunkName: "ConveyancingCalculator" */ '@stories/Widgets/Misc/ConveyancingCalculator/ConveyancingCalculator'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CasesDealtWithListing',
        import: () => import(/* webpackChunkName: "CasesDealtWithListing" */ '@stories/Widgets/Aggregation/CasesDealtWithListing/CasesDealtWithListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Table',
        import: () => import(/* webpackChunkName: "Table" */ '@stories/Widgets/Content/Table/Table'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CtaDividerBanner',
        import: () => import(/* webpackChunkName: "CtaDividerBanner" */ '@stories/Widgets/Dividers/CtaDividerBanner/CtaDividerBanner'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'CookieScript',
        import: () => import(/* webpackChunkName: "CookieScript" */ '@stories/Widgets/Global/CookieScript/CookieScript'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSiteMap',
        import: () => import(/* webpackChunkName: "HtmlSiteMap" */ '@stories/Widgets/Misc/HtmlSiteMap/HtmlSiteMap'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'PaymentDetails',
        import: () => import(/* webpackChunkName: "PaymentDetails" */ '@stories/Widgets/Content/PaymentDetails/PaymentDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentContents',
        import: () => import(/* webpackChunkName: "SplitContentContents" */ '@stories/Widgets/Content/SplitContentContents/SplitContentContents'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PersonBio',
        import: () => import(/* webpackChunkName: "PersonBio" */ '@stories/Widgets/Content/PersonBio/PersonBio'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleCtaDividerBanner',
        import: () => import(/* webpackChunkName: "ArticleCtaDividerBanner" */ '@stories/Widgets/Dividers/ArticleCtaDividerBanner/ArticleCtaDividerBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleSources',
        import: () => import(/* webpackChunkName: "ArticleSources" */ '@stories/Widgets/Content/ArticleSources/ArticleSources'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContentRelatedArticles',
        import: () => import(/* webpackChunkName: "ArticleSources" */ '@stories/Widgets/Content/SplitContentRelatedArticles/SplitContentRelatedArticles'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactFormHeroBanner',
        import: () => import(/* webpackChunkName: "ContactFormHeroBanner" */ '@stories/Widgets/Banners/ContactFormHeroBanner/ContactFormHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    }
];
